<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :title="title"
      :ok-button-props="{ props: { loading: submitting } }"
      @ok="handleSubmit"
    >
      <a-form
        class="sfm-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item :label="label">
          <a-input
            v-decorator="['name', {
              rules: [
                { required: true, message: '请输入名称' },
                { max: 10, message: '最多10个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { DocumentCategoryModel } from "@/models/document_category.js"
const documentCategoryModel = new DocumentCategoryModel()

export default {
  name: 'EditDocumentCategoryModal',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    rootId: {
      type: Number,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'edit_document_category' }),
      submitting: false,
      title: '编辑种类',
      label: '种类名称'
    }
  },
  created() {
    this.fetchTitle()
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    fetchTitle() {
      if (this.rootId !== 0) {
        if (this.parentId === this.rootId) {
          this.title = '编辑二级分类'
          this.label = '二级分类名称'
        } else {
          this.title = '编辑三级分类'
          this.label = '三级分类名称'
        }
      }
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const params = {
            ...values,
            root_id: this.rootId,
            parent_id: this.parentId
          }
          documentCategoryModel.update(params, this.id).then((res) => {
            if (res.code === 0) {
              this.$notification.open({
                message: '提示',
                description: res.message,
                duration: 2
              })
              this.isShow = false
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
